import { Injectable } from '@angular/core';
import { BundleActionService } from './bundle-action.service';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiResponseModel } from '@lms/shared/models';

@Injectable({ providedIn: 'root' })
export class BundleActionFacadeService {
  refreshProgram$ = new Subject<void>();

  constructor(private bundleActionService: BundleActionService) {}

  setStartButtonClicked(bundleId: number, startButtonClicked: boolean): Observable<ApiResponseModel<null>> {
    return this.bundleActionService.setStartButtonClicked(bundleId, startButtonClicked).pipe(
      tap(() => {
        this.refreshProgram$.next();
        return;
      }),
    );
  }
}
