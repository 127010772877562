import { Injectable } from '@angular/core';
import { ApiResponseModel } from '@lms/shared/models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class BundleActionService {
  constructor(private apiService: ApiService) {}

  setStartButtonClicked(bundledId: number, startButtonClicked: boolean): Observable<ApiResponseModel<null>> {
    return this.apiService.executePost<ApiResponseModel<null>>(`bundles/start_button_clicked/${bundledId}`, { startButtonClicked });
  }
}
