import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN, OrderTypeEnum, OrderTypeForHelpScoutEnum, UserEventClass, UserModel } from '@lms/shared/models';
import { CurrentUserStorage } from '@lms/shared/storages';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { LogService } from '@lms/shared/services/api-services';

declare const Beacon: any;

const DEFAULT_AVATAR = '/assets/images/no_image/ico_profile.svg';
const USER_ID_AUTOINCREMENT_START = 100000000;

@Injectable({ providedIn: 'root' })
export class HelpScoutService {
  user: UserModel;
  private beaconAlive = false;
  private currentlyUsedBeaconId: null | string = null;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) public environment: Environment,
    private currentUserStorage: CurrentUserStorage,
    private readonly router: Router,
    private logService: LogService,
  ) {
    this.user = this.currentUserStorage.getCurrentUser() || ({} as UserModel);
  }

  startListeningRoutes(): Observable<void> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap((event: NavigationEnd | any) => combineLatest([this.currentUserStorage.isMergedB2CDashboard$, of(event)])),
      map(([isMergedB2cDashboard, event]) => {
        const url: string = event.url;

        if (url.includes('/courses/take')) {
          this.destroy();
          return;
        }

        if (isMergedB2cDashboard) {
          this.init(this.environment.helpScoutB2bStudentId || this.environment.helpScoutId);
          return;
        }

        if (this.currentUserStorage.isUserFullImmersion()) {
          this.init(this.environment.helpScoutFIDashboardId || this.environment.helpScoutId);
          return;
        }

        this.init(this.environment.helpScoutId);
        return;
      }),
    );
  }

  public init(helpScoutId?: string): void {
    if (!helpScoutId || helpScoutId === this.currentlyUsedBeaconId) {
      return;
    }

    this.currentlyUsedBeaconId = helpScoutId;

    this.destroy();

    this.sendScoutId(helpScoutId);

    this.currentUserStorage.currentUser$.subscribe(user => {
      if (user?.id) {
        this.prefill(user);
      }
    });
  }

  beaconReadyEventHandler(): void {
    Beacon('on', 'ready', () => {
      Beacon('config', {
        contactForm: {
          customFieldsEnabled: true,
          showName: true,
        },
        showPrefilledCustomFields: false,
      });
    });
  }

  public prefill(user: UserModel): void {
    if (Beacon && user.id) {
      this.sendIdentify(user);
      Beacon('prefill', {
        name: user.firstname + ' ' + user.lastname,
        email: user.email,
        avatar: user.image?.urls?.default || new URL(DEFAULT_AVATAR, this.environment.dashboardPanelUrl).href,
      });
    }
  }

  public sendScoutId(helpScoutId: string): void {
    this.beaconAlive = true;
    Beacon('init', helpScoutId);
  }

  open(): void {
    Beacon('open');
    this.log();
  }

  destroy(): void {
    if (this.beaconAlive) {
      this.beaconAlive = false;
      Beacon('destroy');
    }
  }

  sendIdentify(user: UserModel): void {
    let orderType: OrderTypeEnum | undefined;
    if (user.orders.length) {
      orderType = user.orders
        .filter(element => Object.values(OrderTypeForHelpScoutEnum).includes(element.type.toString() as OrderTypeForHelpScoutEnum))
        .map(element => element.type)
        .pop();
    }
    const identifyObj: Record<any, any> = {
      name: user.firstname + ' ' + user.lastname,
      avatar: user.image?.urls?.default || new URL(DEFAULT_AVATAR, this.environment.dashboardPanelUrl).href,
      UID: (USER_ID_AUTOINCREMENT_START + user.id).toString(),
      Programs: user.programs.programsEnrolledNames.join(),
      DateRegistered: user.createdAt.substring(0, 10),
      programs: user.programs.programsEnrolledNames.join(),
      Enrollments: user.programs.programsEnrolledNames.length
        ? user.programs.programsEnrolledNames.join()
        : user.courses.coursesEnrolledNames.join(),
      AmountSpent: user.totalMoneySpent.amount || 0,
      company: user.companies.length ? user.companies[0].name : 'CFI',
      OrderType: orderType ? orderType[0] + orderType.slice(1).toLowerCase() : '',
    };
    Beacon('identify', identifyObj);
  }

  public onOpenListener(): void {
    if (!Beacon) return;
    Beacon('on', 'open', () => {
      this.log();
    });
  }

  private log(): void {
    if (this.currentUserStorage.isUserSubscriber()) {
      const pathname = window.location.origin + window.location.pathname;
      const linkDest = window.location.origin + '/help';
      const pricingPlan = this.currentUserStorage.isUserFullImmersion() ? 'Full-Immersion' : 'Self-Study';
      const linkLogObj = new UserEventClass('link_click_help', 'help', 'nav_left', pathname, linkDest, pricingPlan);

      this.logService.linkClickLogEvent(linkLogObj).pipe(take(1)).subscribe();
    }
  }
}
