import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { EncryptDecryptService } from '@lms/dashboard-ui/user-code';
import {
  BundleModel,
  Environment,
  ENVIRONMENT_TOKEN,
  OrderModel,
  PaymentMethodModel,
  PricingPlanModel,
  UserModel,
} from '@lms/shared/models';
import { CurrentUserStorage, SubscriptionService } from '@lms/shared/storages';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime, filter } from 'rxjs';
import { ThirdPartyLoaderService } from './third-party-loader.service';

@Injectable({ providedIn: 'root' })
export class GtmService {
  gtmCode = this.environment.gtmCode;
  userFirstSession = false;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) public environment: Environment,
    @Inject(DOCUMENT) private document: any,
    private thirdParty: ThirdPartyLoaderService,
    private cookies: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private encrypt: EncryptDecryptService,
    private subscriptionService: SubscriptionService,
    private userStorage: CurrentUserStorage,
  ) {}

  public initGtmService(): Promise<void> {
    this.route.queryParams.pipe(debounceTime(200)).subscribe(() => {
      new URLSearchParams(window.location.search.slice(1)).forEach((value, key) => {
        if (key === 'utm_campaign') {
          if (this.cookies.get('utm_campaign')) this.cookies.delete('utm_campaign');
          this.cookies.set('utm_campaign', value, { expires: 365 });
        }
        if (key === 'utm_medium') {
          if (this.cookies.get('utm_medium')) this.cookies.delete('utm_medium');
          this.cookies.set('utm_medium', value, { expires: 365 });
        }
      });
    });
    this.keepQueryParamsOnNavigationCancel(this.router);

    return this.thirdParty
      .loadExternalScriptBody(
        `
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
       var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
       j.src='${this.environment.gtmUrl}'+i+dl;f.parentNode.insertBefore(j,f);})
       (window,document,'script','dataLayer','${this.environment.gtmCode}');
      `,
      )
      .then(() => {
        if (this.environment.gtmCode) {
          this.pushTag({
            referrer: 'https://learn.corporatefinanceinstitute.com',
          });
        }
      });
  }

  public async pageView(gtmTag: any): Promise<void> {
    if (this.gtmCode === '') {
      return;
    }
    await this.pushTag(gtmTag);
  }

  public async insertIdentifyingUser(user: UserModel | null): Promise<void> {
    if (this.gtmCode === '') {
      return;
    }
    await this.pushTag({
      userStatus: user ? 'User' : 'Guest',
      userID: user ? user.id : 0,
    });
  }

  public async insertSignupUser(user: UserModel): Promise<void> {
    if (this.gtmCode === '') {
      return;
    }
    await this.pushTag({
      event: 'signup',
      userSignupID: user.id,
    });
  }

  public async beginCheckout(plan: PricingPlanModel, student: UserModel | null, bundle: BundleModel, userFirstSession: boolean) {
    const utm_campaign = this.cookies.get('utm_campaign');
    const utm_medium = this.cookies.get('utm_medium');

    console.warn('COOKIES', utm_medium, utm_campaign);

    await this.pushTag({ ecommerce: null }); // Clear the previous ecommerce object.
    await this.pushTag({
      event: 'begin_checkout',
      ecommerce: {
        currency: plan.price?.currency || null,
        value: plan.price?.amount, // cost of B2C Subscription plan
        existing_account: !this.userFirstSession,
        items: [
          {
            item_id: bundle?.id,
            item_name: bundle?.name,
            /*
             * affiliation should be the value of utm_campaign when utm_medium = affiliate (set on session start)
             * (https://monosnap.com/direct/ocjYDj6eMHkrRv6mCM6UBPKcNmuThT)
             */
            affiliation: utm_medium === 'affiliate' ? utm_campaign : null, // new,
            item_variant: 'B2C',
            price: plan.price?.amount,
            quantity: 1,
          },
        ],
      },
    });
  }

  public async processSignupUser(user: UserModel, method: string, social?: string | null): Promise<void> {
    if (this.gtmCode === '') {
      return;
    }
    await this.pushTag({
      event: 'sign_up',
      student_id: user.id,
      method,
      sign_up_social_source: social,
      firstName: user.firstname,
      lastName: user.lastname,
      email: user.email,
      customerSHA1Email: this.encrypt.encryptSHA1(user.email),
    });
  }

  public async processUserLogin(user: UserModel | null, method: string, social?: string | null): Promise<void> {
    if (this.gtmCode === '') {
      return;
    }
    await this.pushTag({
      event: 'user_login',
      student_id: user?.id,
      subscription_type:
        this.userStorage.isUserSubscriber() || this.userStorage.isUserFullImmersion()
          ? this.subscriptionService.activeSubscription()?.name
          : 'Single Program',
      login_social_source: social,
    });
  }

  public async insertCreatedOrder(order: OrderModel, student: UserModel | null, paymentMethod: PaymentMethodModel | null): Promise<void> {
    const revenue = order.revenue?.amount || null;
    if (this.gtmCode === '') {
      return;
    }

    const utm_campaign = this.cookies.get('utm_campaign');
    const utm_medium = this.cookies.get('utm_medium');

    await this.pushTag({ ecommerce: null }); // Clear the previous ecommerce object.

    await this.pushTag({
      event: 'purchase-GA4',
      ecommerce: {
        dataLayer_format: 'GA4',
        transaction_id: order.id,
        existing_account: !this.userFirstSession,
        affiliation: utm_medium === 'affiliate' ? utm_campaign : null, // new,
        value: revenue,
        tax: order.tax?.amount,
        currency: order.chargeAmount?.currency || null,
        coupon: order.couponCode,
        country: paymentMethod?.taxResidence.countryCode || null,
        student_id: student?.id,
        firstName: student?.firstname,
        lastName: student?.lastname,
        email: student?.email,
        state: paymentMethod?.taxResidence.stateCode || paymentMethod?.taxResidence.region,
        city: paymentMethod?.taxResidence.city,
        zip: paymentMethod?.taxResidence.zipCode,
        customerSHA1Email: student?.email ? this.encrypt.encryptSHA1(student?.email) : null,
        items: [
          {
            item_id: order.productId,
            item_name: order.name,
            affiliation: utm_medium === 'affiliate' ? utm_campaign : null, // new,
            coupon: order.couponCode,
            discount_percentage:
              order.discount?.amount &&
              order.beforeDiscount?.amount &&
              ((+order.discount?.amount * 100) / +order.beforeDiscount?.amount).toFixed(),
            discount: order.discount?.amount,
            item_variant: 'B2C',
            price: revenue,
            quantity: 1,
          },
        ],
      },
    });
  }

  public async pushTag(obj: Record<any, any>) {
    const layer: any = this.document.defaultView['dataLayer'] as any;
    return layer?.push(obj);
  }

  public logCertificationAndSpecializationPageView(abbreviation: string, programName: string): void {
    const gtmTag = {
      event: 'page',
      pageName: this.route.url,
      'data-abbreviation': abbreviation,
      'data-program-name': programName,
    };

    this.pageView(gtmTag);
  }

  private async keepQueryParamsOnNavigationCancel(router: Router): Promise<void> {
    interface Params {
      [key: string]: string;
    }

    const urlParamKeep = [
      'pricingPlanId',
      'gclid',
      'dclid',
      'fbclid',
      'MSCLKID',
      '_gl',
      '_ga',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
      'utm_source_platform',
      'utm_creative_format',
      'utm_marketing_tactic',
      '_kx',
      'RAN',
    ];

    let isNavigationCancel: boolean = false;
    let paramsKept: any = null;

    /*
    This reducer gets a list of key-value strings from the original query params,
    checks if the param key is included in the list of parameters to keep, adds
    it to the params accumulator (object), and returns the object with the kept
    parameters.
    */
    const keepParamsReducer = (params: Params, currentParam: Array<string>): Params => {
      const [paramKey, paramValue] = currentParam;
      if (urlParamKeep.includes(paramKey)) {
        params[paramKey] = paramValue;
      }
      return params;
    };

    const prepareNavigation = (event: any): void => {
      isNavigationCancel = true;
      const queryParams = router.parseUrl(event.url).queryParams;
      paramsKept = Object.entries(queryParams).reduce(keepParamsReducer, {});
    };

    /*
    This function creates a urlTree using only the kept params.
    The queryParamsHandling is set to '' for removing the orinal query params.
    */
    const navigateWithParams = (event: any): void => {
      if (isNavigationCancel && event.urlAfterRedirects === '/auth/login') {
        const urlTree = router.createUrlTree(['/auth/login'], {
          queryParams: paramsKept,
          queryParamsHandling: '',
          preserveFragment: true,
        });
        this.router.navigateByUrl(urlTree);
        // reset the variables.
        isNavigationCancel = false;
        paramsKept = null;
      }
    };

    // listen to events
    router.events.pipe(filter(e => e instanceof NavigationCancel)).subscribe(e => prepareNavigation(e));
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => navigateWithParams(e));
  }
}
