import { Injectable, NgZone } from '@angular/core';
import { NotificationModel, NotificationType, ViolationModel } from '@lms/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotifierService {
  private notifications$: BehaviorSubject<NotificationModel[]> = new BehaviorSubject<NotificationModel[]>([]);

  constructor(private zone: NgZone) {
    this.notifications$ = new BehaviorSubject<NotificationModel[]>([]);
  }

  public getNotifications(): Observable<NotificationModel[]> {
    return this.notifications$.asObservable();
  }

  public success(message: string, duration: number = 8000): void {
    this.add(message, NotificationType.Success, duration);
  }

  public info(message: string, duration: number = 8000): void {
    this.add(message, NotificationType.Info, duration);
  }

  public error(message: string, duration: number = 8000): void {
    if (message) {
      this.add(message, NotificationType.Error, duration);
    }
  }

  public destroy(notification: NotificationModel): void {
    const notifications = this.notifications$.getValue();
    notifications.splice(notifications.indexOf(notification), 1);
    this.notifications$.next(notifications);
  }

  private add(message: string | ViolationModel, notificationType: NotificationType, duration: number = 8000): void {
    const notifications = this.notifications$.getValue();
    const passMessage = typeof message === 'string' ? message : message.message;
    const notification = new NotificationModel(passMessage, notificationType);

    const dismissWait = (): void => {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.zone.run(() => {
            this.destroy(notification);
          });
        }, duration);
      });
    };

    notifications.push(notification);
    this.notifications$.next(notifications);

    dismissWait();
  }
}
