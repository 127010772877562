import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyLoaderService {
  public loadExternalScript(url: string, id?: string): Promise<any> {
    return new Promise(resolve => {
      const styleElement = document.createElement('script');
      styleElement.src = url;
      styleElement.type = 'text/javascript';
      if (id) styleElement.id = id;
      styleElement.onload = resolve;
      document.head.appendChild(styleElement);
    });
  }

  public loadExternalScriptBody(body: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const blob = new Blob([body], { type: 'application/javascript' });
        const scriptUrl = URL.createObjectURL(blob);

        const scriptElement = document.createElement('script');
        scriptElement.src = scriptUrl;
        scriptElement.onload = () => {
          URL.revokeObjectURL(scriptUrl);
          resolve('Script loaded successfully');
        };
        scriptElement.onerror = error => reject(new Error('Script failed to load'));

        document.head.appendChild(scriptElement);
      } catch (error) {
        reject(error);
      }
    });
  }

  public loadExternalStyles(styleUrl: string): Promise<any> {
    return new Promise(resolve => {
      const styleElement = document.createElement('link');
      styleElement.type = 'text/css';
      styleElement.rel = 'stylesheet';
      styleElement.href = styleUrl;
      styleElement.onload = resolve;
      document.head.appendChild(styleElement);
    });
  }
}
